export const environment = {
  production: false,
  apiurl: "https://ps-dev-api.seamhealth.support/api/",
  billingUrl: "https://ps-billing-dev.seamhealth.support/api/",
  hostUrl: "https://patient-satisfaction.seamhealth.support/",
  flutterkey: "FLWPUBK_TEST-0202d3cdd91f1901a1b469f350edbc4e-X",
  paystackKey: "pk_test_4c745db71c1c976f59be08d13ff17838a5de418c",
  billingUserName: "admin-billing",
  billingPassword: "ghtAxcbVasdFGhTAfsKlTyiacxvn",
  env: "PS-DEV",
  googleOAuth: "492608983999-8rmo9ngfllcimc0s347qoca4aqblalie.apps.googleusercontent.com",
  SOCKET_ENDPOINT: "wss://ps-messaging-dev.seamhealth.support",
  socketUrl: "https://ps-messaging-dev.seamhealth.support/auth/",
  socketUsername: "clientshot.messaging",
  socketPassword: "TRYIEQWT12457WJJQKQJJKLMXFERTAIA",
  companyUrl: "https://clientshot.com/",
  ihvnBar: "ihvn-bar-code-dev",
};
